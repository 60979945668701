import React from "react";
import ProjectCard from "./ProjectCard";
import { GamesData } from "../DATA";

export default function Games() {
    return(
        <section id="projects" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">GAMES</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Games I am proud to present</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">A collection of my most recent game projects, many of which are Game Jam Winners</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    {GamesData.map((game, index) => (
                        <ProjectCard 
                            key={game.title}
                            title={game.title}
                            description={game.description}
                            image={game.image}
                            chips={game.chips}
                            github={game.githubLink}
                            link={game.liveLink}
                            itchIO={game.itchIOLink}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}