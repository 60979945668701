import React from "react";
import SkillCard from "./SkillCard";
import { SkillsData } from "../DATA";

export default function Skills(){
    return(
        <div className="flex flex-col items-center h-fit text-white mt-40">
            <div className="w-[90%] lg:w-[80%] 2xl:w-[65%] h-fit">
                <div className="flex flex-col w-full mb-20 text-center">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">SKILLS</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">My Skillset</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Always learning new languages, heres what I know right now</p>
                </div>
                {/* Main Card */}
                <div className="flex flex-col w-full h-fit bg-slate-700 rounded-2xl">
                    <div className="flex flex-row justify-center mx-0 lg:mx-20 my-20 flex-wrap">
                        {SkillsData.map((skill, index) => (
                            <SkillCard key={skill.name} name={skill.name} src={skill.image}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};