
// Works
import BirchbarkHealth from "../src/images/BirchbarkHealth.png";
import LinkUp from "../src/images/LinkUp.png";
import Erickson from "../src/images/Erickson.png";
import Sabre from "../src/images/Sabre.png";
import MotiveTonight from "../src/images/MotiveTonight.png";

// Icons
import HTMLIcon from "../src/images/HTML.svg";
import CSSIcon from "../src/images/CSS.svg";
import JavaScriptIcon from "../src/images/JavaScript.svg";
import ReactIcon from "../src/images/React.svg";
import NodeIcon from "../src/images/Node.svg";
import NativeIcon from "../src/images/React.svg";
import PythonIcon from "../src/images/Python.svg";
import JavaIcon from "../src/images/Java.svg";
import CIcon from "../src/images/C.svg";
import CPlusIcon from "../src/images/C++.svg";
import CSharpIcon from "../src/images/CSharp.svg";
import PHPIcon from "../src/images/PHP.svg";
import TailwindIcon from "../src/images/Tailwind.svg";
import TypeScriptIcon from "../src/images/Typescript.svg";
import NextIcon from "../src/images/Next.svg";
import GitHubIcon from "../src/images/Github.svg";
import UnityIcon from "../src/images/Unity.svg";
import FigmaIcon from "../src/images/Figma.svg";

// Clubs
import GoogleBasta from "../src/images/GoogleBasta.png";
import QWEB from "../src/images/QWEB.png";
import QHACKS from "../src/images/QHACKS.png";
import QGDC from "../src/images/QGDC.png";

// Projects
import QuizNoteAI from "../src/images/QuizNoteAI.png";
import Stylit from "../src/images/Stylit.png";
import Wishlist from "../src/images/Discord.png";
import Portfolio from "../src/images/Portfolio.png";
import NChessSolver from "../src/images/NChessSolver.png";
import QHacks2023 from "../src/images/QHACKS2023.png";
import QHacks2024 from "../src/images/QHACKS2024.png";
import NotionJobTracker from "../src/images/NotionV2.png";
import JobTracker from "../src/images/JobTracker.png";

// Games
import CrashKitchen from "../src/images/CrashKitchen.png";
import Hamstar from "../src/images/Hamstar.png";
import ArcticMania from "../src/images/ArcticMania.png";
import TinkersTale from "../src/images/TinkersTale.png";
import MirrorMadness from "../src/images/MirrorMadness.png";

export const WorkData = [
    {
        title:"Motive Tonight",
        position:"Mobile Software Engineer",
        description:"Spearheaded mobile app development using JavaScript, TypeScript, React Native, Firebase, and Google Cloud, scaling to 1,000 users and ranking in the top 15 Social Networking apps on IOS within the first day",
        image:MotiveTonight,
        link:"https://whatsthemotivetonight.com/",
        chips:["React Native", "Expo", "JavaScript", "Firebase", "Google Cloud", "Figma"]
    },
    {
        title:"Birchbark Health",
        position:"Software Engineer",
        description:"Founding Software Engineer at an early stage Healthcare startup offering a highly tailored and streamlined healthcare experience for First Nations Peoples and healthcare providers.",
        image:BirchbarkHealth,
        link:"https://www.linkedin.com/company/symptom360/about/",
        chips:["NextJS", "TypeScript", "Figma", "HTML", "CSS", "Tailwind", "JavaScript", "tRPC", "Prisma", ]
    },
    {
        title:"LinkUp",
        position:"Web Programming Intern",
        description:"Web Programming Intern at a job data acquisition company. Employed advanced PHP, Regex, and Proxy techniques to successfully extract data from 22,000 job listings and over 200 companies",
        image:LinkUp,
        link:"https://www.linkup.com",
        chips:["PHP", "Regex", "Proxy"]
    },
    {
        title:"Erickson Building Inc.",
        position:"Web Development Contractor",
        description:"Designed and deployed a business website, leveraging Figma, ReactJS, HTML, and CSS for an optimal user experience, resulting in significant increase of sales",
        image:Erickson,
        link:"https://www.ericksonbuildinginc.com",
        chips:["Figma", "HTML", "CSS", "JavaScript"]
    },
    {
        title:"Sabre",
        position:"Frontend Software Developer Intern",
        description:"I am an incoming Frontend Software Developer Intern at Sabre for the summer of 2024, I will be working in an Agile team to develop internal software tools.",
        image:Sabre,
        link:"https://www.sabre.com",
        chips:["SolidJS","ReactJS","JavaScript","CSS","HTML"]
    }
];

export const SkillsData = [
    {
        "name":"HTML",
        "image":HTMLIcon
    },
    {
        "name":"CSS",
        "image":CSSIcon
    },
    {
        "name":"JavaScript",
        "image":JavaScriptIcon
    },
    {
        "name":"React",
        "image":ReactIcon
    },
    {
        "name":"Node",
        "image":NodeIcon
    },
    {
        "name":"Native",
        "image":NativeIcon
    },
    {
        "name":"Python",
        "image":PythonIcon
    },
    {
        "name":"Java",
        "image":JavaIcon
    },
    {
        "name":"C",
        "image":CIcon
    },
    {
        "name":"C++",
        "image":CPlusIcon
    },
    {
        "name":"C#",
        "image":CSharpIcon
    },
    {
        "name":"PHP",
        "image":PHPIcon
    },
    {
        "name":"Tailwind",
        "image":TailwindIcon
    },
    {
        "name":"TypeScript",
        "image":TypeScriptIcon
    },
    {
        "name":"Next",
        "image":NextIcon
    },
    {
        "name":"GitHub",
        "image":GitHubIcon
    },
    {
        "name":"Unity",
        "image":UnityIcon
    },
    {
        "name":"Figma",
        "image":FigmaIcon
    },
];

export const ClubData = [
        {
            title:"Google Basta",
            description:"Selected to participate in a 10-week mentorship program, where I worked one-on-one with a Google Software Engineer to enhance my technical skills through real-time coding sessions, interview preparation, and professional development.",
            image:GoogleBasta,
            chips:["DSA", "Algorithms", "Leetcode"],
            link:"https://www.projectbasta.com/gswep-code2career"
        },
        {
            title:"Queen's Web Development Club",
            description:"Currently the Co-Chair for the club organizing events and workshops for over 500 students to learn the fundamentals of web development.",
            image:QWEB,
            chips:["ReactJS", "NodeJS", "MongoDB", "ExpressJS", "Figma", "HTML", "CSS", "JavaScript"],
            link:"https://main--qweb-website.netlify.app/"
        },
        {
            title:"QHacks Hackathon",
            description:"Currently the Frontend Technology Director for the hackathon, leading a team of 3 to build the website for the hackathon.",
            image:QHACKS,
            chips:["NextJS", "Tailwind", "ReactJS", "JavaScript", "Figma", "HTML", "CSS", ],
            link:"https://qhacks.io/"
        },
        {
            title:"Queen's Game Development Club",
            description:"Currently a general member in the club, learning the fundamentals of game development. Won the 2022 Game Jam in a team of 4.",
            image:QGDC,
            chips:["Unity", "C#", "Aseprite"],
            link:"https://qgdc.wordpress.com/"
        }
];

export const ProjectData = [
    {
        title:"QuizNote AI",
        description:"Won best use of Generative AI at QHacks 2025 by developing QuizNoteAI, a web application that generates personalized study guides, summaries, and quizzes from course materials, utilizing Next.js for frontend, C# backend, and PostgreSQL for data handling. Integrated Google Gemini and AssemblyAI for AI-powered insights and transcription.",
        image:QuizNoteAI,
        chips:["NextJS", "TypeScript", "ASP.net", "C#", "PostgreSQL", "Google Gemini", "AssemblyAI", "AI", "Figma"],
        githubLink:"https://github.com/SchuylerGood/QuizNoteAI",
        itchIOLink:null,
        liveLink: "https://www.youtube.com/watch?v=77-zDBM269E&ab_channel=SchuylerGood"
    },
    {
        title:"Stylit",
        description:"Stylit is an AI Hairstyle recommendation mobile app, I worked in a team of 4 to create this app for the 2023 Qhacks Hackathon. We used facial recognition to detect the user's face and used machine learning to recommend hairstyles based on their facial structure",
        image:Stylit,
        chips:["AI","Deep/Machine Learning", "React Native", "JavaScript", "Figma", "MLP", "Python", "Facial Recognition","Express JS"],
        githubLink:"https://github.com/DylanWalker1320/Stylit",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"Qhacks 2024 Website",
        description:"I led a team of 3 officers to designed and developed the Qhacks 2024 website using.",
        image:QHacks2024,
        chips:["NextJS", "Tailwind CSS", "JavaScript", "Figma"],
        githubLink:"https://github.com/qhacks/qhacks-website-2024",
        itchIOLink:null,
        liveLink: "https://qhacks.io/"
    },
    {
        title:"Wishlist Discord Bot",
        description:"This discord bot was created for the members of any discord server to create colaborative wishlists using any valid newegg product link. This was my first ever project using the discord API.",
        image:Wishlist,
        chips:["Python", "Discord API", "Web Scraping", "Beautiful Soup"],
        githubLink:"https://github.com/SchuylerGood/Whishlist-Bot",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"Portfolio Website",
        description:"This website was created to showcase my projects and skills. It was created using React and Tailwind CSS.",
        image:Portfolio,
        chips:["React", "Tailwind CSS", "JavaScript", "Figma"],
        githubLink:"https://github.com/SchuylerGood/personal-website-version3",
        itchIOLink:null,
        liveLink: "https://schuylergood.com"
    },
    {
        title:"Qhacks 2023 Website",
        description:"I worked in a team of 3 officers to design and develop the Qhacks 2023 website.",
        image:QHacks2023,
        chips:["React", "HTML", "CSS", "ChakraUI", "JavaScript", "Figma"],
        githubLink:"https://github.com/qhacks/qhacks-website-2023",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"N-Chess Solver",
        description:"I worked in a team of 4 for a school project using predicate logic where we created a program that could solve the N-Queen's problem (TODO:add link) with King, Queen, Rook, Bishop, and Horse pieces.",
        image:NChessSolver,
        chips:["Python", "Predicate Logic", "Backtracking"],
        githubLink:"https://github.com/SchuylerGood/n-chess-simulation",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"Notion Job Tracker",
        description:"The Notion Job Tracker is a way to organize your job applications, collect the data, visualize and predict certain metrics. This project includes a Notion Template, along with a program using the Notion API.",
        image:NotionJobTracker,
        chips:["Python", "Flask", "Pandas", "MatPlotLib", "CLI", "Next JS", "TypeScript", "Tailwind"],
        githubLink:"https://github.com/SchuylerGood/Application-Visualizer-Notion-API",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"Job Tracker",
        description:"Built a job search and tracking app with Prisma ORM, tRPC, NextJS, and Tailwind to help users manage their job hunt. Added features like user authentication and CRUD operations using Clerk API and ngrok. Also, used Cheerio and Axios to scrape job postings from different websites. In the future I will add AI powered web scraping",
        image:JobTracker,
        chips:["NextJs", "TypeScript", "Prisma", "tRPC", "SupaBase", "Axios", "Cheerio", "Ngrok", "Webhooks"],
        githubLink:"https://github.com/SchuylerGood/job-tracker",
        itchIOLink:null,
        liveLink: "https://job-tracker-black.vercel.app/"
    }
];

export const GamesData = [
    {
        title:"Crash Kitchen",
        description:"Crash Kitchen is a Multiplayer Co-op VR Game where you and a friend work together in a mobile food truck to both cook and fulfill orders while also delivering them to customers! Worked in a team of 5 to create this game for my final project in my undergrad program.",
        image:CrashKitchen,
        chips:["Unity", "C#", "VR", "Networking", "Game Development", "Game Design"],
        githubLink:"https://github.com/DylanWalker1320/CrashKitchen",
        itchIOLink:null,
        liveLink: null
    },
    {
        title:"Mirror Madness",
        description:"Mirror Madness is a 2D platformer with a symmetric twist. Use the two available mirrors to make your way through the puzzle and get to the mineshaft to proceed through the levels. Complete all 10 levels to complete the game.",
        image:MirrorMadness,
        chips:["Unity", "C#", "Pixel Art", "Game Development", "Game Design"],
        githubLink:"https://github.com/oscoson/Mirror-Madness",
        itchIOLink:"https://potatoboi1320.itch.io/mirror-madness",
        liveLink: null
    },
    {
        title:"Hamstar",
        description:"Hamstar is a gravity-based pixel platformer where you must guide and navigate your little hamster through planets and obstacles until it reaches its destined circle of stars! This game won 1st place in the Queen's University 2022 Summer Game Jam.",
        image:Hamstar,
        chips:["Unity", "C#", "Pixel Art", "Game Development"],
        githubLink:"https://github.com/oscoson/Hamstar",
        itchIOLink:"https://oscosan.itch.io/hamstar",
        liveLink: null
    },
    {
        title:"Arctic Mania",
        description:"Arctic Mania is a 2D top-down shooter where you play as a penguin and must fight off waves of enemies. Worked in a team of 8 to create this game.",
        image:ArcticMania,
        chips:["Unity", "C#", "Pixel Art", "Game Development", "Game Design"],
        githubLink:"https://github.com/oscoson/Arctic-Mania",
        itchIOLink:"https://oscosan.itch.io/arctic-mania",
        liveLink: null
    },
    {
        title:"Tinker's Tale",
        description:"Tinker's Tale is a 2D game where you must defeat enemies in a proceduraly generated dungeon, beat the boss, collect loot, and upgrade your weapons. Worked in a team of 3 to create this game for my CISC 226 Game Design course.",
        image:TinkersTale,
        chips:["Unity", "C#", "Pixel Art", "Game Development"],
        githubLink:"https://github.com/CISC-226-W23/226-game-design-project-gdp-7",
        itchIOLink:"https://sky-king2.itch.io/tinkers-tale-version-17",
        liveLink: null
    },
];