import React from "react";
import ProjectCard from "./ProjectCard";
import { ProjectData } from "../DATA";

export default function Projects() {
    return(
        <section id="projects" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">PROJECTS</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Projects I'm proud to present</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Some of my most recent projects that I would love to showcase</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    {ProjectData.map((project, index) => (
                        <ProjectCard
                            key={project.title}
                            title={project.title}
                            description={project.description}
                            image={project.image}
                            chips={project.chips}
                            github={project.githubLink}
                            link={project.liveLink}
                            itchIO={project.itchIOLink}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}