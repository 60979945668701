import React from "react";
import Headshot from "../images/Headshot.png";
import DownloadIcon from "../images/DownloadIcon.svg";
import { motion } from "framer-motion";

export default function About(){
    const downloadButtonClick = () => {
        fetch('Schuyler Good Resume.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Schuyler Good Resume.pdf';
                alink.click();
            })
        })
    }

    return(
        <div className="flex flex-col items-center h-fit text-white mt-40">
            <div className="w-[90%] lg:w-[80%] 2xl:w-[65%]  h-fit">
            <div className="flex flex-col w-full mb-20 text-center">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">ABOUT ME</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Who I am</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">You can reach me at my <motion.a target="_blank" whileHover={{textDecoration:"underline", color: "#2F90C7"}} href="mailto:schuylergood@gmail.com" className="font-bold">Email</motion.a> or <motion.a target="_blank" href="https://www.linkedin.com/in/schuylergood/" className="font-bold" whileHover={{textDecoration:"underline", color: "#2F90C7"}}>LinkedIn</motion.a> to chat about any opportunities</p>
                </div>
                {/* Main Card */}
                <div className="flex flex-col w-full h-fit bg-slate-700 rounded-2xl">
                    <div className="flex flex-col  lg:flex-row m-4 lg:m-8 2xl:m-20">
                        <div className="flex flex-none justify-center items-center w-full lg:w-56 lg:h-56 2xl:w-80 my-8">
                            <img src={Headshot} alt="Schuyler Good" className="rounded-full w-36 h-36 sm:w-44 sm:h-44 md:w-64 md:h-64 lg:w-56 lg:h-56 2xl:w-80 2xl:h-80"></img>
                        </div>
                        <div className="flex flex-1 justify-center items-center">
                            <ul className="list-disc flex flex-col ml-4 mr-2 sm:ml-12 sm:mr-12 md:ml-20 md:mr-20 lg:ml-12 lg:mr-12">
                                <li className="text-xl mx-2 my-1">I'm in my fourth year at Queen's University in Kingston, ON, graduating in Spring 2025, majoring in computer science.</li>
                                <li className="text-xl mx-2 my-1">I started programming in my first year of university and instantly fell in love with how much I could do with it.</li>
                                <li className="text-xl mx-2 my-1">Professional interests: Software Engineering, Web Development, UI Design, Full Stack Development, Game Development, and Prototyping</li>
                                <li className="text-xl mx-2 my-1">Hobbies: Weight Lifting, Movies, Video Games, Travel, Skateboarding, Skiing, and Tennis</li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-2xl mx-4 sm:mx-8 lg:mx-20 my-4 flex flex-col">
                        <p className="text-center "></p>
                        <div className="flex justify-center mb-8">
                            <motion.button className="w-80 h-16 bg-indigo-400 rounded-full font-bold flex items-center justify-center" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} onClick={downloadButtonClick}>
                                <img src={DownloadIcon} alt="download icon" className="w-8 h-8 mr-3"/>
                                <p className="text-lg sm:text-xl lg:text-2xl">Resume Download</p>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};