import React from "react";
import WorkCard from "./WorkCard";
import { WorkData } from "../DATA";

export default function Work() {



    return (
        <section id="work" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">WORK</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Recent Experiences</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Here are some of my recent experiences.</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    {WorkData.map((work, index) => (
                        <WorkCard 
                            key={work.title}
                            title={work.title}
                            position={work.position} 
                            description={work.description} 
                            image={work.image} 
                            link={work.link}
                            chips={work.chips}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};